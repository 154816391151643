export enum FedopsInteraction {
  OpenPaymentPage = 'open-payment-widget',
  JoinUserToChallenge = 'join-to-challenge',
  ViewProgramFromCard = 'view-program-from-card',
  ViewSectionAtSidebarLayout = 'view-section-at-sidebar-layout',
  ViewStepAtSidebarLayout = 'view-step-at-sidebar-layout',
  CompleteStepWithoutFeedback = 'complete-step-without-feedback',
  CompleteStepWithFeedback = 'complete-step-with-feedback',
  UpdateFeedbackData = 'update-feedback-data',
  LeaveFromProgram = 'leave-from-program',
}
