import React from 'react';

import { FormSubmission } from '@wix/ambassador-forms-v4-submission/types';

export interface IQuizContext {
  controllerIsReady: boolean;
  initController: Function;
  sendSubmission: Function;
  lastSubmissionId?: string;
  getSubmission: Function;
  submission?: FormSubmission;
}

export const quizDefaultValue = {
  controllerIsReady: false,
  initController: () => {},
  sendSubmission: () => {},
  lastSubmissionId: null,
  getSubmission: () => {},
  submission: null,
};

export const QuizContext = React.createContext<IQuizContext>(quizDefaultValue);

export function useQuiz(): IQuizContext {
  return React.useContext(QuizContext);
}

export const QuizConsumer = QuizContext.Consumer;
